export const urlHostRest = 'https://kpvs-staff-demo.hardcorp.com.tw:5099';

export const urlHostStaff = 'https://kpvs-staff-demo.hardcorp.com.tw';

export const urlBaseImgStatic = 'https://storage.googleapis.com/cdnkpvs/stage/img';

export const environment = 'dev';

export const timezone = 'Asia/Taipei';

